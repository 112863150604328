import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, slug, description, titleMedia, imageFb }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          siteUrl
        }
      }
    }
  `)

  const info = {
    description:
      "Hurtownia materiałów stomatologicznych Infinity Medical w Bieruniu. Materiały stomatologiczne, dezynfekcja. SDR Plus i Neo Spectra.",
    keyWords: [
      "Materiały stomatologiczne",
      "hurtownia stomatologiczna",
      "SDR Plus",
      "Neo Spectra",
      "materiały dentystyczne",
      "dezynfekcja",
      "materiały do dezynfekcji",
    ],
    titleMedia: "Hurtownia Stomatologiczna Infinity Medical",
    imageFb:
      "https://www.hurtowniazdrowyzabek.pl/static/728284ee79370c899b483b51eab81b71/724c8/main_image3.jpg",
    twitterCreator: "", //twitterID
    twitterUrl: "",
  }

  return (
    <Helmet>
      <html lang="pl" />
      <meta charset="utf-8" />
      <meta
        name="google-site-verification"
        content="q4PnRoFHdEtuzwlUiAO0o_72vFydi_LW_zkxRTXXgko"
      />
      <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
      <meta name="description" content={description || info.description} />
      <meta name="keywords" content={info.keyWords.join(", ")} />
      <meta name="author" content={data.site.siteMetadata.author} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/*GOOGLE*/}
      <meta name="google-site-verification" content="" />

      {/*FACEBOOK*/}
      <meta
        property="og:url"
        content={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleMedia || info.title} />
      <meta
        property="og:description"
        content={description || info.description}
      />
      <meta property="og:image" content={imageFb || info.imageFb} />

      <meta property="og:locale" content="pl_PL" />

      {/*TWITTER*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={info.twitterCreator} />
      <meta name="twitter:site" content={info.twitterUrl} />
      <meta name="twitter:title" content={titleMedia || info.titleMedia} />
      <meta
        name="twitter:description"
        content={description || info.description}
      />
      <meta name="twitter:image" content={imageFb || info.imageFb} />
    </Helmet>
  )
}
export default SEO
