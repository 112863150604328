import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes, faTooth } from "@fortawesome/free-solid-svg-icons"
import { list } from "../../assets/data/pageStructure"

class Navbar extends React.Component {
  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {
    const menu = list.map(item => (
      <li key={item.name} className="nav-bar-item">
        <Link activeClassName="active" className="nav-bar-link" to={item.path}>
          {item.name}
        </Link>
      </li>
    ))

    return (
      <>
        <nav
          id="menu"
          role="navigation"
          aria-label="main navigation"
          className={this.state.clicked ? "" : ""}
        >
          <div className="nav">
            <div className="wrapper-logo-nav">
              <Link to="/" className="nav-bar-logo mobile-no">
                <Img fixed={this.props.logo} objectFit="cover" alt="logo" />
              </Link>

              <ul className={this.state.clicked ? "nav-bar-open" : "nav-bar"}>
                <Link
                  to="/"
                  className="nav-bar-open-logo"
                  onClick={this.handleClick}
                >
                  <Img fixed={this.props.logo} objectFit="cover" alt="logo" />
                </Link>

                {menu}
              </ul>
            </div>
            <div className="info">
              <p>+48 570 133 366</p>
              <p>Faktury: +48 883 670 370</p>
              <p>hurtowniainfinitymedical@gmail.com</p>
            </div>
          </div>
        </nav>

        <div className="nav-bar-mobile">
          <button className="nav-bar-icon" onClick={this.handleClick}>
            <FontAwesomeIcon icon={this.state.clicked ? faTimes : faBars} />
          </button>
        </div>
      </>
    )
  }
}
export default Navbar
