import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { list } from "../../assets/data/pageStructure"

const Footer = props => {
  const menu = list.map(item => (
    <li key={item.name}>
      <Link activeClassName="active" to={item.path}>
        {item.name}
      </Link>
    </li>
  ))

  return (
    <div>
      <footer>
        <div className="container footer-invit">
          <p>Zapraszamy do kontaktu!</p>
          <p>
            Napisz do nas lub zadzwoń i powiedz nam czego potrzebujesz.
            <br />Z przyjemnością odpowiemy na wszystkie Twoje pytania.
          </p>
        </div>
        <div className="footer-info">
          <div className="mobile-no desktop-no"></div>

          <div className="phone-no footer-info-logo">
            <Img fluid={props.logo} objectFit="contain" alt="logo" />
          </div>

          <div>
            <p className="footer-info-header">Adres</p>
            <p>ul. Skowronków 67</p>
            <p>43-155 Bieruń</p>
            <p>Śląskie</p>
            <p>POLAND</p>
          </div>

          <div>
            <p className="footer-info-header">
              Kontakt
              <br />
              +48 570 133 366
            </p>
            <p></p>
            <p>
              Faktury: <br />
              +48 883 670 370
            </p>
            <p className="mail">hurtowniainfinitymedical@gmail.com</p>
          </div>

          <ul className="phone-no">{menu}</ul>

          <div className="mobile-no desktop-no"></div>
        </div>
        <div className="footer-copyrights">
          <p>2021 © Wszystkie prawa zastrzeżone</p>
          <p>Projekt i realizacja:</p>
          <a href="https://iksdesign.pl/" target="blank">
            IKS Design
          </a>
        </div>
      </footer>

      {/*<footer className="footer-grid">
                <div></div>
                <div className="grid-phone">
                    <p>Szukasz rewelacyjnej jakości w niskiej cenie?
              <br />Dobrze trafiłeś! <br />Zadzwoń i zamów!</p>
                    <p>+48 570 133 366</p>
                    <p>Faktury: <br />+48 883 670 370</p>
                    <p>hurtowniazdrowyząbek@gmail.com</p>
                </div>

                <div className="grid-info">
                    <div className="kontakt">
                        <span>
                            <p>Kontakt</p>
                            <p>ul. Ruchu Oporu 205</p>
                            <p>43-220 Bojszowy Nowe</p>
                            <p>Śląskie</p>
                        </span>

                    </div>

                    <div className="infofooter">
                        <p>2021 © Wszystkie prawa zastrzeżone</p>
                        <p>Projekt i realizacja:</p>
                        <Link to="/">
                            IKS Aleksandra Fabian
                        </Link>
                    </div>
                </div>
                <div></div>
            </footer>*/}
    </div>
  )
}

export default Footer
