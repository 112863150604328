import styled from 'styled-components';
import media from "styled-media-query";
import BackgroundImage from 'gatsby-background-image'

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  h2 {
     margin-bottom: 3rem;
     margin-top: 6rem;
  }

  ${media.lessThan("small")`
  max-width: 540px;
  `}

  ${media.between("small", "medium")`
  max-width: 720px;
  `}

  ${media.between("medium", "large")`
  max-width: 960px;
  `}

  ${media.between("large", "huge")`
  max-width: 1200px;
  `}
 
  ${media.greaterThan("huge")`
  max-width: 1600px;
  `}
`;

export const CardWrapper = styled(Container)`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  margin: 5rem auto 7rem auto;

  ${media.lessThan("small")`
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto 2rem auto;
  `}
 
  ${media.between("small", "medium")`
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto 2rem auto;
  `}

  ${media.between("medium", "large")`
  grid-template-columns: repeat(3, 1fr);
  `}

  ${media.between("large", "huge")`
  grid-template-columns: repeat(3, 1fr);
  `}
 
  ${media.greaterThan("huge")`
  grid-template-columns: repeat(3, 1fr);
  `}
`;

export const Baner = styled(BackgroundImage)`
background-color: #659BAD;
height: auto;
text-align: left;

& > div {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, 
        rgba(0,0,0,0) 0%, 
        rgba(0,136,206,0.7) 100%); 
    width: 100%;
    min-height: 40vh;
    display: flex;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 6rem;
}
  
h1, h2 {
  color: rgb(87, 87, 87);
  font-weight: 900;
}
`;