import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CookieConsent from "react-cookie-consent"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../utils/theme"

import Footer from "../footer/footer"
import Navbar from "../navbar/navbar"

const Cookie = styled.span`
  .CookieConsent {
    background-color: ${({ theme }) => theme.color.baseSoftDarkOp} !important;

    p {
      color: white;
      font-size: 16px;
    }

    button {
      background-color: ${({ theme }) => theme.color.white} !important;
      font-weight: 700;
      color: ${({ theme }) => theme.color.base} !important;
    }
  }
`

const Layout = props => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logoN.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logo_mobile: file(relativePath: { eq: "logoN_mobile.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const logo = data.logo.childImageSharp.fluid
  const logo_mobile = data.logo_mobile.childImageSharp.fluid

  return (
    <ThemeProvider theme={theme}>
      <div className="layout-wraper">
        <Navbar logo={logo} />

        <div className="content-wrapper">{props.children}</div>

        <Cookie>
          <CookieConsent
            location="bottom"
            buttonText="Akceptuję"
            declineButtonText="Decline"
            cookieName="gatsby-gdpr-google-analytics"
          >
            <p>
              <b>INFORMACJA DOTYCZĄCA PLIKÓW COOKIES</b>
            </p>
            <p>
              Informujemy, iż w celu optymalizacji treści dostępnych w naszym
              serwisie, dostosowania ich do Państwa indywidualnych potrzeb
              korzystamy z informacji zapisanych za pomocą plików cookies na
              urządzeniach końcowych użytkowników. Pliki cookies użytkownik może
              kontrolować za pomocą ustawień swojej przeglądarki internetowej.
              Dalsze korzystanie z naszego serwisu internetowego, bez zmiany
              ustawień przeglądarki internetowej oznacza, iż użytkownik
              akceptuje stosowanie plików cookies.
            </p>
          </CookieConsent>
        </Cookie>
        <Footer logo={logo_mobile} />
      </div>
    </ThemeProvider>
  )
}

export default Layout
