export const theme = {
    color: {
        font: 'rgba(0, 0, 0, .9)',
        white: 'rgba(255, 255, 255, 1)',
        base: 'rgb(00, 136, 206)',
        baseLight: '#D7EDF5',
        baseDark: 'rgb(2, 29, 63)',
        baseSoftDark: 'rgb(14, 66, 109)',
        baseSoftDarkOp: 'rgba(14, 66, 109, .8)',

        sec1: '#008bc2',
        sec2: 'rgba(225, 225, 225, 1)',
        sec3: 'rgba(225, 225, 225, 1)',
        sec4: 'rgba(225, 225, 225, 1)',
        sec5: 'rgba(225, 225, 225, 1)',

        opacityBlack06: 'rgba(0, 0, 0, .6)',
    },

    font: {
        main: 'Yantramanav',
        header: 'Yantramanav',
        special: 'Yantramanav',

        weight: 300,

        sizeSmall: '16px',
        size: '19px',
    }
}